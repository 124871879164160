import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import {logout} from "../../../../components/Auth";

class Step2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: "",
            locationID: 0,
            locations: [],
            slots: [],
        };

        this.dateChange = this.dateChange.bind(this);
        this.locationChange = this.locationChange.bind(this);
        this.slotChange = this.slotChange.bind(this);
    }

    componentDidMount() {
        axios.get("/api/1/doctor/schedule/locations")
            .then((response) => {
                this.setState({locations: response.data.locations})
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    renderLocations() {
        return this.state.locations.map((location) =>
            <option key={location.locationID} value={location.locationID}>
                {location.name}
            </option>
        );
    }

    slotChange(e) {
        if (!e.target.value) {
            return;
        }

        const slotUUID = e.target.value
        const slotName = this.formatSlot(this.state.slots.filter((slot) => slot.slotUUID === slotUUID)[0]);

        this.props.setScheduleSlot(slotUUID, slotName);
    }

    renderSlots() {
        let groupByHours = {};

        this.state.slots.forEach((slot) => {
            if (!groupByHours[slot.beginHours]) {
                groupByHours[slot.beginHours] = [];
            }

            groupByHours[slot.beginHours].push(
                <Button key={slot.slotUUID}
                        variant={this.props.scheduleSlotUUID === slot.slotUUID ? "success" : "primary"}
                        className="mb-1 mr-1"
                        value={slot.slotUUID}
                        onClick={this.slotChange}>
                    {this.formatSlot(slot)}
                </Button>
            );
        });

        return Object.keys(groupByHours).map((groupKey) => <div key={groupKey}>{groupByHours[groupKey]}</div>);
    }

    formatSlot(slot) {
        return slot.beginHours + ":" + slot.beginMinutes.toString().padStart(2, '0') + " - " + slot.endHours + ":" + slot.endMinutes.toString().padStart(2, '0')
    }

    locationChange(e) {
        if (!e.target.selectedOptions) {
            return;
        }

        let locationID = e.target.selectedOptions[0].value;
        if (locationID.length > 0) {
            locationID = parseInt(locationID);
        }

        const locationName = this.state.locations.filter((location) => location.locationID === locationID)[0].locationName;

        this.setState({locationID: locationID});
        this.props.setLocation(locationID, locationName);
        this.loadSlots(this.state.date, locationID);
    }

    dateChange(e) {
        if (!e.target.value) {
            return;
        }

        this.setState({date: e.target.value});
        this.props.setScheduleDate(e.target.value);
        this.loadSlots(e.target.value, this.state.locationID);
    }

    loadSlots(date, locationID) {
        if (!locationID || locationID <= 0) {
            return;
        }

        if (!date) {
            return;
        }

        axios.get("/api/1/doctor/schedule/locations/" + locationID + "/" + date)
            .then((response) => {
                this.setState({slots: response.data.slots})
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    window.location.reload();
                }
            })
    }

    render() {
        const locations = this.renderLocations();
        const slots = this.renderSlots();

        return <>
            <h3>Шаг 2 - где и когда</h3>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Выберите дату:</Form.Label>
                            <Form.Control type="date"
                                          value={this.props.scheduleDate} min={this.props.minDate} max={this.props.maxDate}
                                          onChange={this.dateChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Выберите центр:</Form.Label>
                            <Form.Control as="select" onChange={this.locationChange} defaultValue={this.props.locationID ? this.props.locationID : "0"}>
                                <option disabled value="0">Выберите из списка</option>
                                {locations}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Выберите слот:</Form.Label><br/>
                            {slots}
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="text-right">
                    <Button type="button" variant="secondary" onClick={this.props.prevStep}>Назад</Button>{' '}
                    <Button type="button" variant="primary" onClick={this.props.nextStep}>Далее</Button>
                </Form.Group>
            </Form>
        </>;
    }
}

export default Step2;
